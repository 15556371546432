
$(document).ready(function(){

  $("a[href*=\\#]:not([href=\\#]):not(.nav-ignore)").click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
       if (target.length) {
         $('html,body').animate({
           scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });



  // $("#contact-form").submit(function(e) {
  //   e.preventDefault();

  //   $(".error", $(this)).hide();

  //   var submitText = $("#input-submit span").html();
  //   $("#input-submit span").html("Please wait");
  //   $("#input-submit").addClass('inactive').attr('disabled', 'disabled');


  //   var formValues = $(this).serializeArray();
  //   var rules = {
  //     'jm-form-name': function(val) {
  //       return val && val.trim().length > 0;
  //     },
  //     'jm-form-email': function(val) {
  //       return val && val.match(/[a-z0-9\.-_]+@[a-z0-9\.-_]+\.[a-z]+/i);
  //     },
  //     'jm-form-message': function(val) {
  //       return val && val.trim().length > 0;
  //     }
  //   }
  //   var valid = true;
  //   var values = {};

  //   for (i in formValues) {
  //     var object = formValues[i];
  //     if (object.name && typeof rules[object.name] == 'function') {
  //       var validObj = rules[object.name].call(this, String(object.value));
  //       if (!validObj) {
  //         valid = false;
  //         $("." + object.name + "-error", $(this)).show();
  //       }
  //     }

  //     if (object.value) {
  //       var name = object.name.match(/^jm-form-(.+?)$/)[1];
  //       if (name) {
  //         values[name] = object.value;
  //       }
  //     }

  //     values['check'] = btoa(values.email);
  //   }

  //   if (!valid) {
  //     $("#input-submit span").html(submitText);
  //     $("#input-submit").removeClass('inactive').attr('disabled', false);    
  //     return;
  //   }

  //   $.post('./form.php', values, function(data) {
  //     $("#alert-contact-success").show();
  //     $("#contact-form").hide();

  //   }, 'json').fail(function() {
  //     $("#alert-contact-error").show();
  //     $("#contact-form").hide();

  //   }).always(function(){
  //     $("#input-submit span").html(submitText);
  //     $("#input-submit").removeClass('inactive').attr('disabled', false);    
  //   });

  // });


});
